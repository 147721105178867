<template>
  <b-card
    title="Create TL-Coach"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="form"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Church Regional"
              label-for="church_regional_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Church Regional"
                rules="required"
              >
                <b-form-input
                  id="church_regional_id"
                  v-model="church_regional_id"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="iFrame URL"
              label-for="iframe_url"
            >
              <validation-provider
                #default="{ errors }"
                name="iFrame URL"
                rules="required"
              >
                <b-form-input
                  id="iframe_url"
                  v-model="iframeUrl"
                  :state="errors.length > 0 ? false:null"
                  placeholder="iFrame URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'tlcoach' }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    Loading,
  },
  directives: {
      Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      fullPage: false,
      church_regional_id: '',
      iframeUrl: '',
      required,
    }
  },
  methods: {
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Set the data
          const data = {
            church_regional_id: this.church_regional_id,
            iframe_url: this.iframeUrl,
          }

          // Check if user has access
          if (!this.$can('add', 'tlcoach')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Save
          this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/tlcoach?token=${process.env.VUE_APP_API_TOKEN}`, data)
            .then(() => {
              // Redirect to lists
              this.isLoading = false
              this.$router.replace({ name: 'tlcoach' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Data has been saved successfully.',
                    },
                  })
                })
            }).catch(error => {
              if (error.response) {
                console.log(error.response)
              }
            })
        }
      })
    },
  },
}
</script>